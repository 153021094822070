.container {
    background-color: black;
    margin-bottom: -4%;
}

.container h1 {
    text-align: center;
    padding-top: 200px;
    color: rgb(222, 222, 222);
}

.container h2 {
    text-align: center;
    padding-bottom: 150%;
    color: rgb(128, 128, 128);
}