@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.light {
    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        background-image: url('../../repositorioImagens/DevWeb.png');
        background-blend-mode: multiply;
        background-size: cover;
        background-position: center;
        background-color: rgb(105, 105, 105);
        padding-top: 1%;
        margin-top: 80px;

    }

    .header {

        text-align: center;
        box-sizing: border-box;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.2s forwards;
    }

    .header h1 {
        font-size: 40px;
        color: #ffffff;
        margin-bottom: 50px;
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
    }

    .header p {
        font-size: 25px;
        color: #ffffff;
        margin-bottom: 60px;
        text-align: left;
        margin-left: 5%;
        font-family: Georgia, 'Times New Roman', Times, serif
    }


    .topicsList {
        width: 100%;
        height: 100%;
        max-width: 90%;
        max-height: 90%;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5%;
        list-style-type: none;
        padding: 5%;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.4s forwards;
        padding-top: 0;

    }


    .topicsList li {
        width: auto;
        height: auto;
        background-color: white;
        margin-bottom: 5%;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    }

    .imagem {
        max-width: 100%;
        max-height: 100%;
    }

    .topicsList a {
        text-decoration: none;
    }

    .topicsList h1 {
        font-size: 15px;
        width: 90%;
        margin: 0;
        text-align: left;
        justify-content: justify;
        margin-left: 5%;
        color: rgb(87, 87, 199);
    }

    .topicsList h1:hover {
        text-decoration: underline;
    }

    .topicsList h2 {
        width: auto;
        max-width: 100%;
        border: 1px solid;
        border-color: rgb(77, 93, 240);
        color: rgb(0, 0, 0);
        text-align: left;
        margin-left: 5%;
        display: inline-block;
        padding: 1%;
        font-size: 15px;
    }

    .topicsList h3 {

        font-size: 12px;
        color: rgb(1, 190, 193);
        margin: 0;
        margin: 5%;
        max-width: 100%;
        text-align: left;

    }

    .topicsList h4 {
        width: auto;
        max-width: 100%;
        border: 1px solid;
        border-color: red;
        text-align: left;
        margin-left: 5%;
        display: inline-block;
        padding: 1%;
        font-size: 15px;
        color: red;
    }


    .topicsList li:hover {
        transform: scale(1.05);
        /* Ajuste conforme necessário */
    }

}

.dark {
    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        background: rgb(12, 12, 14);
        padding-top: 3%;
        margin-top: 3%;
        background-image: url('../../repositorioImagens/DevWeb.png');
        background-blend-mode: multiply;
        background-size: cover;
        background-position: center;
        background-color: rgb(26, 26, 26);
        padding-top: 1%;
        margin-top: 80px;


    }

    .header {

        text-align: center;
        box-sizing: border-box;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.2s forwards;
    }

    .header h1 {
        font-size: 40px;
        color: #66bbbb;
        margin-bottom: 50px;
        text-align: center;
    }

    .header p {
        font-size: 25px;
        color: #a7a7a7;
        margin-bottom: 60px;
        text-align: left;
        margin-left: 5%;
        font-family: Georgia, 'Times New Roman', Times, serif
    }


    .topicsList {
        width: 100%;
        height: 100%;
        max-width: 90%;
        max-height: 90%;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5%;
        list-style-type: none;
        padding: 5%;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.4s forwards;
        padding-top: 0;


    }


    .topicsList li {
        width: auto;
        height: auto;
        background-color: rgb(33, 33, 33);
        margin-bottom: 5%;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    }

    .imagem {
        max-width: 100%;
        max-height: 100%;
    }

    .topicsList a {
        text-decoration: none;
    }

    .topicsList h1 {
        font-size: 25px;
        width: 90%;
        margin: 0;
        text-align: left;
        justify-content: justify;
        margin-left: 5%;
        color: White;
    }

    .topicsList h2 {
        width: auto;
        max-width: 100%;
        border: 1px solid;
        border-color: rgb(77, 93, 240);
        color: rgb(120, 120, 255);
        text-align: left;
        margin-left: 5%;
        display: inline-block;
        padding: 1%;
        font-size: 15px;
    }

    .topicsList h3 {

        font-size: 12px;
        color: rgb(3, 251, 255);
        margin: 0;
        margin: 5%;
        max-width: 100%;
        text-align: left;

    }

    .topicsList h4 {
        width: auto;
        max-width: 100%;
        border: 1px solid;
        border-color: rgb(255, 86, 86);
        text-align: left;
        margin-left: 5%;
        display: inline-block;
        padding: 1%;
        font-size: 15px;
        color: red;
    }


    .topicsList li:hover {
        transform: scale(1.05);
        /* Ajuste conforme necessário */
    }

}

@media (max-width: 1050px) {

    .light,
    .dark {


        .container {
            margin-top: 0px;


        }

        .container h1 {
            padding-top: 60px;
            margin-top: 0;
            font-size: 30px;
            margin-left: 2%;

        }

        .container p {
            font-size: 18px;
        }

        .topicsList {
            grid-template-columns: repeat(1, 1fr);
            margin: 0;
            gap: 1%;
            max-height: 100%;
            padding-bottom: 70%;
        }

        .topicsList h1 {
            margin-top: 0;
            margin-left: 5%;
            font-size: 25px;
            justify-content: justify;


        }



        .topicsList h2 {
            font-size: 15px;
        }

        .topicsList h3 {

            font-size: 10px;


        }

        .topicsList h4 {
            font-size: 15px;

        }

    }
}